.content {
  display: flex;
  //flex-direction: column;
  //margin-left: 2.8125rem;
  min-height: 100vh;
  transition: all 0.3s ease;

  &.navigation-expanded {
    @media screen and (min-width: 1200px) {
      margin-left: 18.75rem;
    }

    @media screen and (min-width: 768px) {
      margin-left: 15rem;
    }
  }
}

.content-body {
  flex: 1;
}

body {
  font-size: 0.875rem;
  background-color: #ececeb;
}

.select_wrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 40px;
  padding-top: 2px;
}

.fax_prefix {
  position: absolute;
  top: 35px;
  left: -3px;
}

.SingleDatePicker {
  .DateInput_input {
    width: 103%;
  }
  .SingleDatePickerInput_calendarIcon {
    padding: 10px;
  }
}

.input_feedback_span {
  padding: 2px;
}

.hide {
  display: none !important;
}

.empv_link {
  color: white;
}

.tux_banner_test {
  height: 3.5rem;
  margin-top: 0.3rem;
  border-radius: 5px;
}
